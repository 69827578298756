import React, { useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

import { accountCreditCardsSelector, accountProfileSelector } from 'state/account/account.selectors';
import { accountGetAllCreditCardsRoutine, accountFetchProfileRoutine } from 'state/account/account.routines';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import PaymentMethods from 'components/payment-methods/payment-methods.components';

const PaymentSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileObject = useSelector(accountProfileSelector);

    if (profileObject === undefined) {
        dispatch(accountFetchProfileRoutine.trigger());
    }

    useEffect(() => {
        dispatch(accountGetAllCreditCardsRoutine.trigger());
    }, []);
    const allPaymentData = useSelector(accountCreditCardsSelector);

    useEffect(() => {
        dispatch(accountGetAllCreditCardsRoutine.trigger());
    }, [profileObject]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.payment.eyebrowText`)}
            title={t(`pages.profile.payment.title`)}
            heading={t(`pages.profile.payment.heading`)}
        >
            <Container fluid>
                {allPaymentData && (
                    <PaymentMethods
                        paymentData={allPaymentData}
                        addNewPaymentButtonLabel={t('pages.profile.payment.labels.addNewPayment')}
                        isProfile={true}
                    />
                )}
            </Container>
        </ProfileLayout>
    );
};

export default PaymentSection;
